import { useParams } from 'react-router-dom';
import './productdetails.css'
import { MdRemove } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import useFetch from '../../../networking/useFetch'
import { URL } from '../../../utils/AppConstants'
import Loader from '../../loader/Loader';
import BreadCrumb from '../../breadcrumb/BreadCrumb';
import { useDispatch } from 'react-redux';
import { add } from '../../../redux/cart';
import { useState } from 'react';
import AlerModal from '../../alert/AlertModal';
import FullImage from '../../fullimage/FullImage';
import failedImage from '../../../assets/no_image.png'

const quantityIconStyle = {
    color: '#333', size: 20
}
const ProductDetails = () => {
    const { id } = useParams()
    const { name } = useParams()
    const [quantity, setQuantity] = useState(1)
    const { data, isPending } = useFetch(URL.getSingelProduct + '?id=' + id)
    const [showAlert, setShowAlert] = useState(false)
    const [showImage, setShowImage] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const dispatch = useDispatch()

    const addToCart = (val) => {
        const data = {
            id: val.id,
            name: val.name,
            photo: val.photo_small,
            quantity: quantity,
            price: val.price
        }
        dispatch(add(data))
        setShowAlert(true)
    }
    const increaseQuantity = () => {
        setQuantity(prev => prev += 1)
    }
    const decreaseQuantity = () => {
        if (quantity === 1)
            return
        setQuantity(prev => prev -= 1)
    }
    const closeAlert = () => {
        setShowAlert(false)
    }
    const fullImage = () => {
        if (imageUrl === '') {
            setImageUrl(URL.images_base + data.photo)
        }
        setShowImage(!showImage)
    }

    const imageLoadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = failedImage
    }
    return (
        <div className='container'>
            {/* alert */}
            <AlerModal showModal={showAlert} title='Product' message={'Added to cart successfully'} method={closeAlert} />
            {/* bread crumb */}
            <BreadCrumb current={name} />
            {/* full image view */}
            <FullImage showModal={showImage} url={imageUrl} close={fullImage} />
            {isPending && <Loader />}
            {data &&
                <div className='p-container'>
                    <div className='p-image-container'>
                        <img className='p-image' src={URL.images_base + data.photo_medium} alt="product" 
                        onClick={fullImage} onError={imageLoadFailed}/>
                    </div>
                    <div className='p-details'>
                        <h2 className='p-name'>{data.name}</h2>
                        <p className="p-price">Rs.{data.price}</p>
                        <p className="p-brand">Brand: <span className='p-brand-name'>{data.brand}</span></p>
                        <p className="p-description">{data.description}</p>
                        <div className="p-add-cart-container">
                            <div className="p-quantity-container">
                                <button className='quantity-button' onClick={decreaseQuantity}>
                                    <MdRemove
                                        style={{ fill: quantityIconStyle.color }} size={quantityIconStyle.size} />
                                </button>
                                <span className='p-quantity'>{quantity}</span>
                                <button className='quantity-button' onClick={increaseQuantity}>
                                    <MdAdd
                                        style={{ fill: quantityIconStyle.color }} size={quantityIconStyle.size} />
                                </button>
                            </div>
                            <button className="p-add-cart-button" onClick={() => addToCart(data)}>Add To Cart</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ProductDetails;