import './downloadapp.css'
import mockup from '../../assets/mockupios.jpeg'
import playstoreImage from '../../assets/google-play-badge.png'
import appstoreImage from '../../assets/appstore-badge.png'

const DownloadApp = () => {
    return (
        <div className="container da-container">
            <img className='mockup' src={mockup} alt="mockup" />
            <div className='mockup-content'>
                <h1 className='heading'>Download Our App Now</h1>
                <p className='desc'>Fast, simple and delightful</p>
                <div className='download-button'>
                    <StoreButton
                        link="https://play.google.com/store/apps/details?id=com.pharmacyonlinedh.dhpharmacy"
                        image={playstoreImage}
                        alt="Google Play button" />
                    <StoreButton
                        link=""
                        image={appstoreImage}
                        alt="App Store button" />
                </div>
            </div>
        </div>
    );
}

export default DownloadApp;

function StoreButton({ link, image, alt }) {
    return (
        <a href={link} rel="noreferrer" target='_blank'><img className='img' src={image} alt={alt} /></a>
    )
}
