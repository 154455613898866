import DownloadApp from './downloadapp/DownloadApp';
import ImageSlider from './image_slider/ImageSlider'
import NewArrivals from './newarrivals/NewArrivals';
import { URL } from '../utils/AppConstants';
import Loader from './loader/Loader';
import { useEffect, useState } from 'react';
import useFetch from '../networking/useFetch'

const Home = () => {
    const { data, isPending, error } = useFetch(URL.getHome)

    return (
        <>
            {isPending && <Loader />}
            {data &&
                <>
                    {data.sliding_banners.length > 0 && <ImageSlider data={data.sliding_banners} />}
                    {data.new_arrivals.length > 0 && <NewArrivals data={data.new_arrivals} />}
                </>
            }
            <DownloadApp />
        </>
    );
}

export default Home;