import React from 'react'
import './downloads.css'
import { URL } from '../../utils/AppConstants'
import useFetch from '../../networking/useFetch'
import FullScreenLoader from '../loader/FullScreenLoader'
import AppLogo from '../../assets/logo.png'

const apk_name = 'DHPharmacy'
export default function Downloads() {
    const { data, isPending, error } = useFetch(URL.getApk)

    return (
        <div className='container'>
            <FullScreenLoader showModal={isPending} />
            <h1 className='cart-title'>Downloads</h1>
            {data &&
                <div className='item-download'>
                    <img className='app-logo' src={AppLogo} alt='DHPharmacy apk DHPharmacy App' />
                    <span className='file-name'>{apk_name}</span>
                    <div className='size-container'>
                        <span className='apk-meta-text'>Size: {data.size} M</span>
                        <span className='apk-meta-text'>Version: {data.version}</span>
                    </div>
                    <span className='apk-meta-text'>Updated at: {data.updated_at}</span>
                    <a className='download-btn' download={`${apk_name}.apk`} href={URL.images_base + data.apk}>
                        <span className='download-text'>Download</span>
                    </a>
                </div>
            }
        </div>
    )
}
