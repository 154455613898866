import { categories_surgical } from "../utils/AppConstants";
import BreadCrumb from "./breadcrumb/BreadCrumb";
import Sidebar from "./products/sidebar/Sidebar";
import { Outlet, useParams } from "react-router-dom";
import './products.css'

const Cosmetics = () => {
    const { id } = useParams()
    const currentTitle = id ? id.replaceAll('-', " ") : 'Surgical Goods'
    return (
        <div className="container">
            <BreadCrumb current={currentTitle} />
            <div className="container-product">
                <Sidebar list={categories_surgical} />
                <div className="main">
                    <h2 className="title">{currentTitle}</h2>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Cosmetics;