import { createSlice } from "@reduxjs/toolkit"
export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: []
    },
    reducers: {
        hydrate: (state, action) => {
            state.cart = action.payload
        },
        add: (state, action) => {
            const payload = action.payload
            var found = state.cart.find(data => data.id === payload.id)
            if (found) {
                const index = state.cart.indexOf(found)
                found.quantity = found.quantity + payload.quantity
                state.cart[index] = found
            }
            else {
                state.cart = [...state.cart, action.payload]
            }
        },
        update_quantity: (state, action) => {
            const payload = action.payload
            state.cart[payload.index] = payload.item
        },
        remove: (state, action) => {
            state.cart = state.cart.filter(data => data.id !== action.payload)
        },
        emptyCart: (state, action) => {
            state.cart = []
        }
    }
})

export const { add, hydrate, remove, update_quantity,emptyCart } = cartSlice.actions
export default cartSlice.reducer
