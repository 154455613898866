import { useSelector } from "react-redux"

export const Post = async (url, body) => {
    let promise = new Promise((success, failed) => {
        fetch(url, {
            method: 'POST',
            body: body
        })
            .then(res => {
                return res.json()
            })
            .then((resObj) => {
                // console.log(resObj);
                if (resObj.status_code !== 200) {
                    throw Error(resObj.message)
                    // const errorDetails = Object.values(resObj.error_details)
                    // throw JSON.stringify(errorDetails)
                    // if (errorDetails.length === 0) throw Error(resObj.message)
                    // errorDetails.forEach(element => {
                    //     console.log(element);
                    // });
                }
                success(resObj.message)
            })
            .catch(err => {
                // console.log(err);
                if (err.name === 'AbortError') return //aborted
                // setIsPending(false)
                // setError(err.message)
                failed(err.message)
            })
    })
    return promise
    // return { data, isPending, error }
}