import React from 'react'
import './formerror.css'

export default function FormError({ showError }) {
    return (
        <div className='error-container' style={showError ? {} : { display: 'none' }}>
            <p className='error-detail'>{showError}</p>
        </div>
    )
}
