import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { FaCartPlus } from 'react-icons/fa'
import {
  MdKeyboardArrowDown, MdKeyboardArrowUp,
  MdMenu, MdAdd, MdRemove
} from 'react-icons/md'
import './header.css'
import HeaderCategories from './HeaderCategories'
import { categories_surgical, categories_medicines } from '../../utils/AppConstants'
import { useSelector } from 'react-redux'
import SearchBox from '../search/SearchBox'

const Header = () => {
  const { cart } = useSelector(state => state.cart)

  // const handleMenuClick = () => {
  //   hideNavBar()
  // }
  const handleShowCategory = (id) => {
    if (id === 1) {
      document.getElementsByClassName('div-category')[0].classList.toggle('active')
      document.getElementsByClassName('div-category')[1].classList.remove('active')

      return
    }
    document.getElementsByClassName('div-category')[1].classList.toggle('active')
    document.getElementsByClassName('div-category')[0].classList.remove('active')

  }
  // const hideNavBar = () => {
  //   document.getElementsByClassName('nav-bar')[0].classList.toggle('active')
  // }

  return (
    <header className='container'>
      <Link to='/' className="app-name">
        <img src={logo} alt="app logo" />
        <h2>DH<br />Pharmacy & Cosmetics</h2>
      </Link>
      <div className='nav-container'>
        <div className='nav-inner'>
          {/* search */}
          <SearchBox />
          {/* menu bar */}
          {/* <div className='menu' id='menu'>
            <p>MENU</p>
            <MdMenu className='menu-button' onClick={handleMenuClick} />
          </div> */}
          {/* navbar */}
          <nav className='nav-bar'>
            <Link to='/' className='nav-link nav-item'>
              <span className='nav-name'>Home</span>
            </Link>
            {/* medicines */}
            <div className='dropdown nav-item'>
              <div className='nav-dropdown-name'>
                <Link className='nav-link' to='/medicines'>
                  <span className='nav-name'>Medicines</span>
                </Link>
                {/* <MdKeyboardArrowDown className='down-arrow' onClick={() => handleShowCategory(1)} /> */}
              </div>
              {/* <div className='div-category'>
                <HeaderCategories list={categories_medicines}/>
              </div> */}
            </div>
            {/* cosmetics */}
            <div className='dropdown nav-item'>
              <div className='nav-dropdown-name'>
                <Link className='nav-link' to='/surgical-goods'>
                  <span className='nav-name'>Surgical</span>
                </Link>
                <MdKeyboardArrowDown className='down-arrow' onClick={() => handleShowCategory(2)} />
              </div>
              <div className='div-category'>
                <HeaderCategories list={categories_surgical}/>
              </div>
            </div>
            <Link to='/medicines/Prescription' className='nav-link nav-item order-now'>
              <span className='nav-name'>Order Medicine Now</span>
            </Link>
            <Link to='/contactus' className='nav-link nav-item'>
              <span className='nav-name'>Contact Us</span>
            </Link>
          </nav>
        </div>

        {/* cart button */}
        <Link to='/cart' className='cart-button'>
          <FaCartPlus className='cart-icon'/>
          <h5>Your Cart</h5>
          <span className='cart-total'>{cart.length}</span>
        </Link>
      </div>
    </header>
  );
}

export default Header;