import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import './sidebar.css'

const Sidebar = ({ list }) => {

    const handleCategoryclick = () => {
        document.getElementsByClassName('sidebar-header-container')[0]
            .classList.toggle('active')
        document.getElementsByClassName('categories-list')[0]
            .classList.toggle('active')
    }
    const hideList = () => {
        handleCategoryclick()
    }
    return (
        <div className="main-sidebar">
            <div className='sidebar-header-container' onClick={handleCategoryclick}>
                <h4 className='sidebar-title'>Categories</h4>
                <MdKeyboardArrowDown className='category-arrow' />
            </div>
            <ul className='categories-list'>
                {list.map((p, i) => (
                    <li key={i}>
                        <NavLink style={({ isActive }) => (
                            { color: isActive ? "#DD4A48" : "" }
                        )}
                            to={p.base_link + p.name.replaceAll(" ", "-")}
                            onClick={hideList}>
                            {p.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Sidebar;