import { Link } from "react-router-dom";

const HeaderCategories = ({ list }) => {
    return (
        <>
            <h4>Categories</h4>
            <div className="categories">
                {list.map((p, i) => (
                    <Link key={i} to={p.base_link + p.name}>
                        <span>{p.name}</span>
                    </Link>
                ))}
            </div>
        </>
    );
}

export default HeaderCategories;