import { Link } from 'react-router-dom';
import './breadcrumb.css'
const BreadCrumb = ({ current }) => {
    return (
        <div className="container-bread">
            {/* <ul> */}
            <Link to="/">
                <span>Home</span>
            </Link>
            <span className='current-bread'>{current}</span>
            {/* </ul> */}
        </div>
    );
}

export default BreadCrumb;