import { Outlet } from 'react-router-dom'
// import Home from './components/Home';
import Header from './components/header/Header';
// import Products from './components/products/Products';
import './App.css'
import Footer from './components/footer/Footer';

function App() {
  return (
    <div className="app">
      <Header />
      <div className='app-container'>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
