import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//redux store
import store from './redux/store';
import { Provider } from 'react-redux';
//pages
import Home from './components/Home';
// import Products from './components/products/Products';
import ProductsList from './components/products/productslist/ProductsList';
import Medicines from './components/Medicines';
import Cosmetics from './components/Cosmetics'
// import Prescription from './components/prescription/Prescription';
import { hydrate } from './redux/cart';
import ProductDetails from './components/products/productdetails/ProductDetails';
import Cart from './components/cart/Cart';
import ContactUs from './components/contactus/ContactUs';
import Downloads from './components/downloads/Downloads';

//disabling logs
if (process.env.NODE_ENV !== "development")
  console.log = () => { };
//saving data on state change
store.subscribe(() => {
  try {
    localStorage.setItem('cart', JSON.stringify(store.getState().cart.cart))
  }
  catch (e) {

  }
})
//getting data for initial state from local storage
const getCartFromLocal = () => {
  try {
    const data = localStorage.getItem('cart')
    if (data) {
      return JSON.parse(data)
    }
    else {
      return []
    }
  }
  catch (e) {
    return []
  }
}
const cart = getCartFromLocal()
if (cart) {
  store.dispatch(hydrate(cart))
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename='/'>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={<Home />} />
          <Route path="medicines" element={<Medicines />} >
            <Route index element={<ProductsList tab='Medicines' />} />
            <Route path=':id' element={<ProductsList tab='Medicines' />} />
          </Route>
          <Route path="surgical-goods" element={<Cosmetics />} >
            <Route index element={<ProductsList tab='Surgical Goods' />} />
            <Route path=':id' element={<ProductsList tab='Surgical Goods' />} />
          </Route>
          <Route path='products/:name/:id' element={<ProductDetails />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/downloads' element={<Downloads />} />
          <Route path='*' element={<div style={{textAlign:'center', paddingTop:'2rem', color:'gray'}}>Page not found</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
