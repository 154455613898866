import { useSelector } from "react-redux";
import { MdDelete } from 'react-icons/md'
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { MdRemove } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import { MdMyLocation } from 'react-icons/md'
import './cart.css'
import { URL } from "../../utils/AppConstants";
import { useDispatch } from "react-redux";
import { remove, update_quantity, emptyCart } from "../../redux/cart";
import { useEffect, useState } from "react";
import FullScreenLoader from "../loader/FullScreenLoader";
import AlerModal from "../alert/AlertModal";
import FailedImage from '../../assets/no_image_small.png'
import FormError from "../alert/error/FormError";
import { Post } from "../../networking/Post";

const quantityIconStyle = {
    color: '#333', size: 20
}

const Cart = () => {
    const { cart } = useSelector(state => state.cart)
    const dispatch = useDispatch()
    const [total, setTotal] = useState(0)
    const [location, setLocation] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [orderItems, setOrderItems] = useState([])
    const [showError, setShowError] = useState(null)

    useEffect(() => {
        setTotal(0)
        setOrderItems([])
        cart.forEach(p => {
            const price = p.price * p.quantity
            setTotal(prev => prev + price)
            setOrderItems(prev => [...prev, {
                product_id: p.id,
                quantity: p.quantity
            }])
        });
    }, [cart])

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
            const coords = position.coords
            setLocation(`${coords.latitude},${coords.longitude}`)
        });
    }
    const placeOrder = async () => {
        if (validatePhone(phone)) {
            setIsLoading(true)
            let formData = new FormData()
            formData.append('phone_number', phone)
            // un comment these when api updated
            formData.append('address', address)
            formData.append('location', location)
            formData.append('products', JSON.stringify(orderItems))
            Post(URL.order, formData)
                .then(res => {
                    setIsLoading(false)
                    setShowError(null)
                    // console.log(resObj);
                    setAlertMessage(res)
                    setShowAlert(true)
                })
                .catch(err => {
                    // console.log(err);
                    setIsLoading(false)
                    setShowError(err)
                });
            //     fetch(URL.order, {
            //         method: 'POST',
            //         body: formData
            //     })
            //         .then(res => {
            //             if (!res.ok) {
            //                 throw Error('could not fetch the data for that resource')
            //             }
            //             return res.json()
            //         })
            //         .then(resObj => {
            //             setIsLoading(false)
            //             // console.log(resObj);
            //             setAlertMessage(resObj.message)
            //             setShowAlert(true)
            //         })
            //         .catch(e => {
            //             setIsLoading(false)
            //             // console.log(e.message);
            //             alert(e.message)
            //         })
        }
    }
    const validatePhone = (phone) => {
        const reg = new RegExp('^[0-9]+$')
        if (phone.length < 11 || !reg.test(phone)) {
            setShowError('Valid phone number is required')
            return false
        }
        return true
    }

    const resetCart = () => {
        setAddress('')
        setPhone('')
        setLocation('')
        setAlertMessage('')
        dispatch(emptyCart())
        setShowAlert(false)
    }

    return (
        <div className="container">
            {/* loader */}
            <FullScreenLoader showModal={isLoading} />
            {/* alert */}
            <AlerModal showModal={showAlert} title='Order' message={alertMessage} method={resetCart} />
            <BreadCrumb current='Cart' />
            <h1 className="cart-title">Shopping Cart</h1>
            {/* table */}
            {cart.length > 0 ?
                <div>
                    <table className="cart-table">
                        <thead>
                            <tr>
                                <th>Items</th>
                                <th>Quantity</th>
                                {/* <th>Unit Price</th> */}
                                <th>Total</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map((item, i) => <CartRow item={item} i={i} key={i} dispatch={dispatch} />)}
                        </tbody>
                    </table>
                    {/* details container */}
                    <div className="cart-sub-container">
                        <div className="cart-user-details">
                            <h3>Details</h3>
                            <div className="cart-form">
                                <label className="required">Phone number</label>
                                <input className="form-input" type="text" name="phone_number" maxLength={11}
                                    onChange={(val) => setPhone(val.target.value)} />
                                <label>Address</label>
                                <input className="form-input" type="text"
                                    onChange={(val) => setAddress(val.target.value)} />
                                <label>Location</label>
                                <div className="location-container">
                                    <input className="form-input location-text"
                                        placeholder="Press location button ->" disabled
                                        value={location} />
                                    <button className="location-button" onClick={getLocation}>
                                        <MdMyLocation style={{ fill: '#11468F' }} size={25} />
                                    </button>
                                </div>
                                {/* error Alert */}
                                <FormError showError={showError} />
                                <button className="place-order" onClick={placeOrder}>Place order</button>
                            </div>
                        </div>
                        {/* summary */}
                        <div className="summary-container">
                            <div className="sub">
                                <h3>Summary</h3>
                                <div className="summary-row">
                                    <span>Sub Total</span>
                                    <span className="sub-total">Rs. {total}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                :
                <div className="empty-cart">
                    Cart is empty
                </div>
            }
        </div>
    );
}


const CartRow = ({ item, i, dispatch }) => {
    const removeItem = (val) => {
        dispatch(remove(val))
    }

    const increaseQuantity = (item, index) => {
        var newItem = Object.assign({}, item)
        newItem.quantity += 1
        dispatchQuantity(newItem, index)
    }

    const decreaseQuantity = (item, index) => {
        if (item.quantity === 1)
            return
        var newItem = Object.assign({}, item)
        newItem.quantity -= 1
        dispatchQuantity(newItem, index)
    }

    const dispatchQuantity = (item, index) => {
        const data = {
            item: item,
            index: index
        }
        dispatch(update_quantity(data))
    }
    const imageLoadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = FailedImage
    }
    return (
        <tr>
            <td className="item-cart">
                <div className="item-cart-container">
                    <img className="cart-image" src={URL.images_base + item.photo} alt="product"
                        onError={imageLoadFailed} />
                    <div className="item-cart-container-sub">
                        <p className="cart-item-name">{item.name}</p>
                        <p className="cart-item-price">{item.price}</p>
                    </div>
                </div>

            </td>
            <td>
                <div className="cart-qunatity-container">
                    <button className='quantity-action' onClick={() => decreaseQuantity(item, i)}>
                        <MdRemove
                            style={{ fill: quantityIconStyle.color }} size={quantityIconStyle.size} />
                    </button>
                    <span className="quantity">{item.quantity}</span>
                    <button className='quantity-action' onClick={() => increaseQuantity(item, i)}>
                        <MdAdd
                            style={{ fill: quantityIconStyle.color }} size={quantityIconStyle.size} />
                    </button>
                </div>
            </td>
            {/* <td>Rs. {item.price}</td> */}
            <td>Rs. {item.quantity * item.price}</td>
            <td>
                <button className="cart-delete" onClick={() => removeItem(item.id)} >
                    <MdDelete style={{ fill: '#DD4A48' }} size={25} />
                </button>

            </td>
        </tr>
    )
}
export default Cart;