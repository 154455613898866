import { useState } from 'react';
import './search.css'
import { URL } from '../../utils/AppConstants';
import { Link } from 'react-router-dom';
import failedImage from '../../assets/no_image_small.png'

var controller = new AbortController()
var searchTimer = null
const SearchBox = () => {
    const [query, setQuery] = useState('')
    const [data, setData] = useState([])
    const [isEmpty, setIsEmpty] = useState(false)

    const handleQueryChange = (event) => {
        const val = event.target.value
        setQuery(val)
        if (searchTimer != null) {
            clearTimeout(searchTimer)
            searchTimer = null
        }
        searchTimer = setTimeout(() => {
            resetData()
            getList(val)
        }, 500)
    }

    const resetData = () => {
        controller.abort()
        // console.log('aborted')
        controller = new AbortController()
        setData([])
    }

    const getList = async (query) => {
        if (query == '')
            return console.log('requested', query);
        const url = URL.search + '?search=' + query

        fetch(url, { method: 'GET', signal: controller.signal })
            .then(res => {
                if (!res.ok) {
                    throw Error('could not fetch the data for that resource')
                }
                return res.json()
            })
            .then((res) => {
                // console.log(res);
                const dataObj = res.data
                setData(dataObj)
                setIsEmpty(dataObj.length == 0)
            })
            .catch(err => {
                // console.log(err);
            })
    }

    function ListItem({ item }) {
        const clearList=()=>{
            setQuery('')
            setData([])
        }
        const loadFailed=({target})=>{
            target.onerror = null; // prevents looping
            target.src = failedImage
        }
        return (
            <Link className='search-row' to={`/products/${item.name}/${item.id}`} onClick={clearList}>
                <img className='search-image' src={URL.images_base+item.photo_small} alt="product"
                 onError={loadFailed}/>
                <span className='search-text'>{item.name}</span>
            </Link>
        )
    }

    return (
        <div className='search-box'>
            <input className='search-input' type="text" placeholder='Search products'
                onChange={handleQueryChange} value={query}/>
            <div className='search-data' style={data.length===0?{visibility:'hidden'}: {visibility:"visible"}}>
                {data && data.map(item => <ListItem item={item} key={item.id} />)}
            </div>
        </div>
    );
}

export default SearchBox;