import './prescription.css'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { useRef, useState } from 'react';
import { MdMyLocation } from 'react-icons/md'
import { URL as url } from '../../utils/AppConstants';
import FullScreenLoader from '../loader/FullScreenLoader';
import AlerModal from '../alert/AlertModal';
import FormError from '../alert/error/FormError';
import { Post } from '../../networking/Post';

const Prescription = () => {
    const fileInput = useRef()
    const [selectedFile, setSeletedFile] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [location, setLocation] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [days, setDays] = useState('')
    const [details, setDetails] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showError, setShowError] = useState(null)

    const openFileSelector = () => {
        fileInput.current.click()
    }
    const handleFileClick = (e) => {
        const file = e.target.files[0]
        if (file) {
            setSeletedFile(file)
            setImageUrl(URL.createObjectURL(file))
        }
        else {
            setSeletedFile('')
            setImageUrl('')
        }
    }
    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
            const coords = position.coords
            setLocation(`${coords.latitude},${coords.longitude}`)
        });
    }

    const placeOrder = () => {
        if (selectedFile == '') {
            setShowError('Please select picture of prescription')
        }
        else if (validatePhone(phone)) {
            setIsLoading(true)
            setShowError(null)
            var formData = new FormData()
            formData.append('days', days)
            formData.append('comment', details)
            formData.append('address', address)
            formData.append('location', location)
            formData.append('phone_number', phone)
            formData.append('photo', selectedFile, imageUrl)

            Post(url.prescription, formData)
                .then(res => {
                    // console.log(res);
                    setIsLoading(false)
                    setShowError(null)
                    setAlertMessage(res)
                    setShowAlert(true)
                })
                .catch(err => {
                    // console.log(err);
                    setIsLoading(false)
                    setShowError(err)
                })
        }
    }

    const validatePhone = (phone) => {
        const reg = new RegExp('^[0-9]+$')
        if (phone.length !== 11 || !reg.test(phone)) {
            setShowError('Valid phone number is required')
            return false
        }
        return true
    }
    const resetForm = () => {
        fileInput.current.value = ''
        setSeletedFile('')
        setImageUrl('')
        setDays('')
        setDetails('')
        setAddress('')
        setPhone('')
        setLocation('')
        setAlertMessage('')
        setShowAlert(false)
    }

    return (
        <div className="prescription-container">
            <FullScreenLoader showModal={isLoading} />
            {/* alert */}
            <AlerModal showModal={showAlert} title='Order' message={alertMessage} method={resetForm} />
            {/* body */}
            <p className='desc'>Order your medicines with the doctor's prescription</p>
            {/* form */}
            <div className='presc-form-row'>
                <span className='prescription-label required'>Select picture</span>
                <div className='upload-image-container'>
                    <button className='upload-button' onClick={openFileSelector}>
                        <MdOutlineCloudUpload style={{ fill: '#11468F' }} size={24} />
                        <input ref={fileInput} type="file" hidden onChange={handleFileClick} />
                    </button>
                    {imageUrl && <img className='prescription-image' src={imageUrl} alt="seleted" />}
                </div>
            </div>
            <div className='presc-form-row'>
                <span className='prescription-label'>Medicine days</span>
                <input className='presc-form-input' type="number" value={days}
                    onChange={(e) => setDays(e.target.value)} />
            </div>
            <div className='presc-form-row'>
                <span className='prescription-label'>Details</span>
                <div className='presc-form-input'>
                    <textarea className='text-area' cols="30" rows="5" value={details}
                        onChange={(e) => setDetails(e.target.value)} />
                </div>
            </div>
            <div className='presc-form-row'>
                <span className='prescription-label required'>Phone number</span>
                <input className='presc-form-input' type="number"
                    onChange={(e) => setPhone(e.target.value)} value={phone} />
            </div>
            <div className='presc-form-row'>
                <span className='prescription-label'>Address</span>
                <input className='presc-form-input' type="text"
                    onChange={(e) => setAddress(e.target.value)} value={address} />
            </div>
            <div className='presc-form-row'>
                <span className='prescription-label'>Location</span>
                <input className="presc-form-input"
                    placeholder="Press location button ->" disabled
                    value={location} />
                <button className="presc-location-button" onClick={getLocation}>
                    <MdMyLocation style={{ fill: '#DD4A48' }} size={25} />
                </button>
            </div>
            {/* error Alert */}
            <FormError showError={showError} />
            <button className="presc-place-order" onClick={placeOrder}>Place order</button>
        </div>
    );
}

export default Prescription;