import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { add } from '../../redux/cart';
import AlerModal from '../alert/AlertModal';
import './newarrival.css'
import FailedImage from '../../assets/no_image.png'
import { URL } from '../../utils/AppConstants';

const NewArrivals = ({ data }) => {
    const dispatch = useDispatch()
    const [showAlert, setShowAlert] = useState(false)

    const addToCart = (val) => {
        const data = {
            id: val.id,
            name: val.name,
            photo: val.photo_small,
            quantity: 1,
            price: val.price
        }
        dispatch(add(data))
        setShowAlert(true)
    }
    const closeAlert = () => {
        setShowAlert(false)
    }


    return (
        <div className="container-new">
            {/* alert */}
            <AlerModal showModal={showAlert} title='Product' message={'Added to cart successfully'} method={closeAlert} />
            <h1 className='title-new'>New Arrivals</h1>
            {/* list */}
            <div className='nal-container container'>
                {data.map(p => <ListItem p={p} key={p.id} addToCart={addToCart} />)}
            </div>
        </div>
    );
}
function ListItem({ p, addToCart }) {
    const loadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = FailedImage
    }
    return (
        <div className='nal-item'>
            <Link to={`/products/${p.name}/${p.id}`}>
                {/* <div className='nal-image-container'> */}
                <img className='nal-image' src={URL.images_base + p.photo} alt="product"
                    onError={loadFailed} />
                {/* </div> */}
                <h4 className='nal-product-name'>{p.name}</h4>
                <p className='nal-product-price'>Rs.{p.price}</p>
            </Link>
            <button className='nal-cart-button' onClick={() => addToCart(p)}>Add to Cart</button>
        </div>
    )
}

export default NewArrivals;