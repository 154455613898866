import { categories_medicines } from "../utils/AppConstants";
import { Outlet, useParams } from "react-router-dom";
import BreadCrumb from "./breadcrumb/BreadCrumb";
import Sidebar from "./products/sidebar/Sidebar";
import Prescription from './prescription/Prescription'
import './products.css'

const Medicines = () => {
    const { id } = useParams()
    const currentTitle = id ? id.replaceAll('-', " ") : 'Medicines'
    return (
        <div className="container">
            <BreadCrumb current={currentTitle} />
            <div className="container-product">
                {/* <Sidebar list={categories_medicines} /> */}
                <div className="main">
                    <h2 className="title">{currentTitle}</h2>
                    {
                        id === 'Prescription' ?
                            <Prescription /> :
                            <Outlet />
                    }
                </div>
            </div>
        </div>
    );
}

export default Medicines;