import Modal from "react-modal/lib/components/Modal";
import './fullimage.css'
import { MdClose } from 'react-icons/md'
import failedImage from '../../assets/no_image.png'

Modal.setAppElement('#root')
const FullImage = ({ showModal, close, url }) => {
    const loadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = failedImage
    }
    return (
        <Modal isOpen={showModal} contentLabel="Full image modal"
            className="image-modal-container"
            overlayClassName="image-modal-overlay"
        >
            <img className="full-image" src={url} alt="full" onError={loadFailed}/>
            <button className="close-image" onClick={close}>
                <MdClose style={{ fill: 'black' }} fontSize={25} />
            </button>
        </Modal>
    );
}

export default FullImage;