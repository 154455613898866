import Modal from "react-modal/lib/components/Modal";
import Loader from "./Loader";
import './loader.css'

Modal.setAppElement('#root')
const FullScreenLoader = ({ showModal }) => {
    return (
        <Modal isOpen={showModal} contentLabel="Loading Modal"
            className="loading-modal-container">
            <Loader />
        </Modal>
    );
}

export default FullScreenLoader;