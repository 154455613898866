const BASE_URL = 'https://api.pharmacyonlinedh.com/api'
// const BASE_URL = 'http://127.0.0.1:8000/api'
export const PAGE_SIZE = 20
// status codes
export const SUCCESS_CODE = 200
export const ORDER_SUCCESS_CODE = 201
export const URL = {
    images_base: 'https://api.pharmacyonlinedh.com',
    // images_base: 'http://127.0.0.1:8000',
    getProductsByFilter: BASE_URL + '/get-products',
    getSingelProduct: BASE_URL + '/get-single-product',
    search: BASE_URL + '/get-products',
    order: BASE_URL + '/create-order',
    demand: BASE_URL + '/create-demand',
    prescription: BASE_URL + '/create-prescription',
    getHome: BASE_URL + '/home',
    getApk: BASE_URL + '/get-apk'
}
/* category lists */
// medicine
const CM_BASE = '/medicines/'
export const categories_medicines = [
    // { base_link: CM_BASE, name: 'Medicines' },
    { base_link: CM_BASE, name: 'Surgical Goods' },
    // { base_link: CM_BASE, name: 'Prescription' }
]
// cosmetics
// const CC_BASE = '/cosmetics/'
// export const categories_cosmetics = [
//     { base_link: CC_BASE,name: 'baby care' },
//     { base_link: CC_BASE,name: 'oral care' },
//     { base_link: CC_BASE,name: 'skin care' },
//     { base_link: CC_BASE,name: 'bath & soap' },
//     { base_link: CC_BASE,name: 'personal care' },
//     { base_link: CC_BASE,name: "men's care" },
//     { base_link: CC_BASE,name: "hair care" },
//     { base_link: CC_BASE,name: "fragrances" },
//     { base_link: CC_BASE,name: "makeup" },
// ]
// surgical
const CATEGORIES_SURGICAL_BASE = '/surgical-goods/'
export const categories_surgical = [
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'general' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'bandages' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'anesthesia' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'laproscopic' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'cardiac' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'icu care' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'dialysis' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'sutures' },
    { base_link: CATEGORIES_SURGICAL_BASE, name: 'other' },
]

//phone number
export const PHONE = '03362177721'
export const SOCIAL_LINKS = {
    whatsapp: `https://api.whatsapp.com/send?phone=${PHONE}`,
    facebook: 'https://www.facebook.com/dhpharmacycosmetics/',
    instagram: 'https://www.instagram.com/dh.pharmacy/'
}
export const ADDRESS = 'Gujrat Pakistan'
export const EMAIL = 'dhpharmacycosmetics@gmail.com'
