import { MdPhone } from 'react-icons/md'
import { MdLocationPin } from 'react-icons/md'
import { MdEmail } from 'react-icons/md'
import DownloadApp from '../downloadapp/DownloadApp'
import './contactus.css'
import { ADDRESS, EMAIL, PHONE, SOCIAL_LINKS } from '../../utils/AppConstants';


const iconStyle = {
    color: '#DD4A48', size: 30
}
const ContactUs = () => {
    return (
        <div className="container">
            <h2 className='contact-title'>Contact Us</h2>
            <div className='contact-container'>
                <div className='contact-social-div'>
                    <h3 className='contact-sub-title'>Connect on Social media</h3>
                    <SocialButton
                        link={SOCIAL_LINKS.facebook}
                        imageURl="https://img.icons8.com/fluency/48/000000/facebook.png"
                        text='Facebook' />
                    <SocialButton
                        link={SOCIAL_LINKS.instagram}
                        imageURl="https://img.icons8.com/fluency/48/000000/instagram-new.png"
                        text='Instagram' />
                    <SocialButton
                        link={SOCIAL_LINKS.whatsapp}
                        imageURl="https://img.icons8.com/fluency/48/000000/whatsapp.png"
                        text='Whatsapp' />
                </div>
                <div className='contact-info-div'>
                    <h3 className='contact-sub-title'>Get Office info</h3>
                    <div className='contact-info-row'>
                        <MdLocationPin style={{ fill: iconStyle.color }} size={iconStyle.size} />
                        <div className='info-content'>
                            <p className='info-title'>OUR ADDRESS</p>
                            <p className='info-desc'>{ADDRESS}</p>
                        </div>
                    </div>
                    <div className='contact-info-row'>
                        <MdPhone style={{ fill: iconStyle.color }} size={iconStyle.size} />
                        <div className='info-content'>
                            <p className='info-title'>PHONE NUMBER</p>
                            <p className='info-desc'>{PHONE}</p>
                        </div>
                    </div>
                    <div className='contact-info-row'>
                        <MdEmail style={{ fill: iconStyle.color }} size={iconStyle.size} />
                        <div className='info-content'>
                            <p className='info-title'>EMAIL ADDRESS</p>
                            <p className='info-desc'>{EMAIL}</p>
                        </div>
                    </div>
                </div>
            </div>

            <DownloadApp />
        </div>
    );
}

export default ContactUs;

function SocialButton({ link, imageURl, text }) {
    return (
        <div>
            <a href={link} rel="noreferrer" target='_blank' className='contact-social-button'>
                <img className='contact-social-image' src={imageURl} alt='Social' />
                <span className='contact-social-text'>{text}</span>
            </a>
        </div> 
    )
}