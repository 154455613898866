import Modal from "react-modal/lib/components/Modal";
import './alertmodal.css'

Modal.setAppElement('#root')
const AlerModal = ({ showModal, method, title, message }) => {
    return (
        <Modal isOpen={showModal} contentLabel="Alert Modal"
            className="alert-modal-container"
            overlayClassName="alert-modal-overlay">
                <div className="alert-container">
                    <h3 className="alert-title">{title}</h3>
                    <p className="alert-desc">{message}</p>
                    <button className="alert-button" onClick={method}>Ok</button>
                </div>
        </Modal>
    );
}

export default AlerModal;