import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './image_slider.css'
import failedImage from '../../assets/no_image.png'
import { URL } from '../../utils/AppConstants';

//require 1300x400 image for slide show

const imageStyles = {
    default: 'mySlides fade ',
    active: 'mySlides fade slideActive'
}
const dotStyles = {
    default: 'dot',
    active: 'dot activeDot'
}

var isVisible
var timer
const ImageSlider = ({ data }) => {
    const [active, setActive] = useState(-1)
    useEffect(() => {
        isVisible = true
        automateSlides()
        return () => isVisible = false
    }, [])

    const changeActive = (pos) => {
        setActive(pos)
    }
    const automateSlides = () => {
        const total = data.length - 1
        if (isVisible) {
            setActive((prev) => {
                if (prev === total)
                    return 0
                return prev + 1
            })
            if (data.length > 1)
                timer = setTimeout(() => {
                    automateSlides()
                }, 3000);
        }
    }

    const imageLoadFailed = ({ target }) => {
        target.onerror = null; // prevents looping
        target.src = failedImage
    }
    return (
        <div className='slideshow-container'>
            {data.map((p, i) => (
                <Link
                    className={active === i ? imageStyles.active : imageStyles.default}
                    key={p.id} to={p.url ? p.url : ''}>
                    <img className='image' src={URL.images_base + p.photo} alt="banner" onError={imageLoadFailed} />
                </Link>
            ))}
            <div className='dot-container'>
                {data.map((p, i) => (
                    <span key={p.id} className={active === i ? dotStyles.active : dotStyles.default} onClick={() => changeActive(i)}></span>
                ))}
            </div>
        </div>
    );
}

export default ImageSlider;