import { ADDRESS, EMAIL, PHONE, SOCIAL_LINKS } from '../../utils/AppConstants';
import './footer.css'
import { MdPhone } from 'react-icons/md'
import { MdLocationPin } from 'react-icons/md'
import { MdEmail } from 'react-icons/md'

const Footer = () => {
    return (
        <footer className='footer-container'>
            {/* <div className='container'> */}
            <div className='grid-footer container'>
                <div className='grid-tab'>
                    <h4 className='footer-heading'>Social</h4>
                    <SocialButton
                        link={SOCIAL_LINKS.facebook}
                        imageURl="https://img.icons8.com/fluency/48/000000/facebook.png"
                        text='Facebook' />
                    <SocialButton
                        link={SOCIAL_LINKS.instagram}
                        imageURl="https://img.icons8.com/fluency/48/000000/instagram-new.png"
                        text='Instagram' />
                    <SocialButton
                        link={SOCIAL_LINKS.whatsapp}
                        imageURl="https://img.icons8.com/fluency/48/000000/whatsapp.png"
                        text='Whatsapp' />
                </div>
                <div className='grid-tab'>
                    <h4 className='footer-heading'>Contact Info</h4>
                    <div className='address-row'>
                        <MdPhone className='footer-contact-icon'/>
                        <span>{PHONE}</span>
                    </div>
                    <div className='address-row'>
                        <MdEmail className='footer-contact-icon' />
                        <span>{EMAIL}</span>
                    </div>
                    <div className='address-row'>
                        <MdLocationPin className='footer-contact-icon'/>
                        <span>{ADDRESS}</span>
                    </div>
                </div>
            </div>
            <hr className='footer-line'/>
            <div className='container footer-bottom'>
                <p className='copyright'>© 2022 <span>DH
                    Pharmacy & Cosmetics.</span> All rights reserved.</p>
            </div>
            {/* </div> */}

        </footer>
    );
}

export default Footer;

function SocialButton({ link, imageURl, text }) {
    return (
        <div>
            <a href={link} rel="noreferrer" target='_blank' className='social-container'>
                <img className='social-image' src={imageURl} alt='Social' />
                <span className='social-text'>{text}</span>
            </a>
        </div>

    )
}