import { Link, useParams } from 'react-router-dom';
import './productslist.css'
import { PAGE_SIZE, URL } from '../../../utils/AppConstants';
import { useEffect, useRef, useState, useCallback } from 'react';
import Loader from '../../loader/Loader';
import EmptyList from '../EmptyList';
//redux
import { useDispatch } from 'react-redux';
import { add } from '../../../redux/cart';
import AlerModal from '../../alert/AlertModal';
import FailedImage from '../../../assets/no_image.png'

var PAGE_NUMBER = 1
var controller = null

const ProductsList = ({ tab }) => {
    let { id } = useParams()
    const [data, setData] = useState(null)
    // const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [hasMoreData, setHasMoreData] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const dispatch = useDispatch()
    const observe = useRef()
    const lastProductRef = useCallback(node => {
        handleLoadMore(node)
    }, [isLoading, hasMoreData])

    useEffect(() => {
        abortController()
        controller = new AbortController()
        PAGE_NUMBER = 1
        setData(null)
        // setError(null)
        setIsEmpty(false)
        requestData()
        return () => controller.abort()
    }, [id])
    function abortController() {
        if (controller != null) {
            controller.abort()
            controller = null
        }
    }

    const requestData = async () => {
        setLoading(true)
        const sub_category = (id && tab !== 'Medicines') ? id.replaceAll("-", " ") : ''
        const url = URL.getProductsByFilter + '?page=' + PAGE_NUMBER + '&filter=' + tab + '&sub_category=' + sub_category

        fetch(url, { method: 'GET', signal: controller.signal })
            .then(res => {
                if (!res.ok) {
                    throw Error('could not fetch the data for that resource')
                }
                return res.json()
            })
            .then((res) => {
                // setError(null)
                // console.log(res);
                const dataObj = res.data
                setHasMoreData(dataObj.length === PAGE_SIZE)

                if (PAGE_NUMBER === 1) {
                    setData(dataObj)
                    setIsEmpty(dataObj.length === 0)
                }
                else {
                    setData([...data, ...dataObj])
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
                // if (err.name === 'AbortError') {
                //     console.log('fetch aborted');
                // }
                // else {
                setLoading(false)
                // alert(err.message)
                // }
            })
    }
    const handleLoadMore = (node) => {
        if (isLoading) return
        if (observe.current) observe.current.disconnect()
        observe.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreData) {
                PAGE_NUMBER += 1
                requestData()
                // console.log('Visible');
            }
        })
        if (node) observe.current.observe(node)
    }

    const addToCart = (val) => {
        const data = {
            id: val.id,
            name: val.name,
            photo: val.photo_small,
            quantity: 1,
            price: val.price
        }
        dispatch(add(data))
        setShowAlert(true)
    }
    const closeAlert = () => {
        setShowAlert(false)
    }
    function ListItem({ p, addToCart, index }) {
        const loadFailed = ({ target }) => {
            target.onerror = null; // prevents looping
            target.src = FailedImage
        }
        if (data.length === index + 1) {
            return <div className='product-item' ref={lastProductRef}>
                <Link to={`/products/${p.name}/${p.id}`}>
                    <img src={URL.images_base + p.photo_medium} alt="product" onError={loadFailed} />
                    <h4 className='product-name'>{p.name}</h4>
                    <p className='product-price'>Rs.{p.price}</p>
                </Link>
                <button onClick={() => addToCart(p)}>Add to Cart</button>
            </div >

        }
        return <div className='product-item'>
            <Link to={`/products/${p.name}/${p.id}`}>
                <img src={URL.images_base + p.photo_medium} alt="product" onError={loadFailed} />
                <h4 className='product-name'>{p.name}</h4>
                <p className='product-price'>Rs.{p.price}</p>
            </Link>
            <button onClick={() => addToCart(p)}>Add to Cart</button>
        </div >
    }
    return (
        <div>
            {/* show main loader for first page */}
            {isLoading && PAGE_NUMBER === 1 && <Loader />}
            {/* alert */}
            <AlerModal showModal={showAlert} title='Product' message={'Added to cart successfully'} method={closeAlert} />
            {/* data list */}
            {data &&
                <div className='product-list'>
                    {data.map((product, index) => <ListItem p={product} key={product.id} addToCart={addToCart} index={index} />)}
                </div>
            }
            {/* empty list */}
            {isEmpty && <EmptyList />}
            {/* run for load more */}
            {isLoading && PAGE_NUMBER !== 1 && <Loader />}
        </div>

    );
}

export default ProductsList;